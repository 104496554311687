/**
 * Do not edit directly
 * Generated on Thu, 05 Sep 2024 10:59:39 GMT
 */

export const biColorBaseWhite = "#ffffff";
export const biColorBaseGray50 = "#f0f2f4";
export const biColorBaseGray100 = "#e1e5e9";
export const biColorBaseGray200 = "#c3cad2";
export const biColorBaseGray300 = "#a6b0bb";
export const biColorBaseGray400 = "#a6b0bb";
export const biColorBaseGray500 = "#6a7986";
export const biColorBaseGray600 = "#546471";
export const biColorBaseGray700 = "#404b54";
export const biColorBaseGray800 = "#2a3138";
export const biColorBaseGray900 = "#14191c";
export const biColorBaseGreen50 = "#e8f8f3";
export const biColorBaseGreen100 = "#d3f0e7";
export const biColorBaseGreen200 = "#a6e2cf";
export const biColorBaseGreen300 = "#79d3b7";
export const biColorBaseGreen400 = "#4cc59f";
export const biColorBaseGreen500 = "#21b688";
export const biColorBaseGreen600 = "#19926d";
export const biColorBaseGreen700 = "#136d52";
export const biColorBaseGreen800 = "#0d4936";
export const biColorBaseGreen900 = "#07241b";
export const biColorBaseRed50 = "#fcece8";
export const biColorBaseRed100 = "#f8d9d2";
export const biColorBaseRed200 = "#f1b4a4";
export const biColorBaseRed300 = "#eb8e77";
export const biColorBaseRed400 = "#e56849";
export const biColorBaseRed500 = "#de411d";
export const biColorBaseRed600 = "#b13516";
export const biColorBaseRed700 = "#852812";
export const biColorBaseRed800 = "#591b0b";
export const biColorBaseRed900 = "#2c0d08";
export const biColorBaseYellow50 = "#fcf6eb";
export const biColorBaseYellow100 = "#faecd7";
export const biColorBaseYellow200 = "#f4d9b1";
export const biColorBaseYellow300 = "#efc789";
export const biColorBaseYellow400 = "#e9b362";
export const biColorBaseYellow500 = "#e4a13a";
export const biColorBaseYellow600 = "#b6812f";
export const biColorBaseYellow700 = "#896024";
export const biColorBaseYellow800 = "#5b4018";
export const biColorBaseYellow900 = "#2d200d";
export const biColorBasePurple50 = "#f2e6fc";
export const biColorBasePurple100 = "#e6ccfa";
export const biColorBasePurple200 = "#cd99f4";
export const biColorBasePurple300 = "#b466ed";
export const biColorBasePurple400 = "#9b33e8";
export const biColorBasePurple500 = "#8114e2";
export const biColorBasePurple600 = "#660eb5";
export const biColorBasePurple700 = "#4d0788";
export const biColorBasePurple800 = "#34035b";
export const biColorBasePurple900 = "#19002d";
export const biColorNeutralFaint = "#f0f2f4";
export const biColorNeutralBright = "#e1e5e9";
export const biColorNeutralDefault = "#c3cad2";
export const biColorNeutralShade = "#a6b0bb";
export const biColorPrimaryFaint = "#e8f8f3";
export const biColorPrimaryBright = "#4cc59f";
export const biColorPrimaryDefault = "#21b688";
export const biColorPrimaryShade = "#19926d";
export const biColorSuccessFaint = "#e8f8f3";
export const biColorSuccessBright = "#4cc59f";
export const biColorSuccessDefault = "#21b688";
export const biColorSuccessShade = "#19926d";
export const biColorWarningFaint = "#fcf6eb";
export const biColorWarningBright = "#e9b362";
export const biColorWarningDefault = "#e4a13a";
export const biColorWarningShade = "#b6812f";
export const biColorErrorFaint = "#fcece8";
export const biColorErrorBright = "#e56849";
export const biColorErrorDefault = "#de411d";
export const biColorErrorShade = "#b13516";
export const biColorAiFaint = "#f2e6fc";
export const biColorAiFaintShade = "#e6ccfa";
export const biColorAiBright = "#9b33e8";
export const biColorAiDefault = "#8114e2";
export const biColorAiShade = "#660eb5";
export const biColorBorderBright = "#c3cad2";
export const biColorBorderDefault = "#a6b0bb";
export const biColorBorderShade = "#a6b0bb";
export const biColorTextOnDark = "#ffffff";
export const biColorTextBright = "#6a7986";
export const biColorTextDefault = "#14191c";
export const biColorBackground = "#ffffff";
export const biColorOutline = "#a6b0bb"; // The color of the outline around focused elements.
export const biOpacityDisabled = 0.5; // The opacity of a disabled element.
export const biOpacityActive = 0.5; // The opacity of an element when it’s actively clicked.
export const biOpacityBackdrop = 0.2; // The opacity of a backdrop, such as behind a modal.
export const biSizeScaling = 1; // The scaling factor that affects the font, space and control sizes. Set the `data-bi-scaled` attribute on an element to let the variable go into effect.
export const biSizeFontXxs = 10;
export const biSizeFontXs = 12;
export const biSizeFontS = 14;
export const biSizeFontM = 16;
export const biSizeFontL = 18;
export const biSizeFontXl = 24;
export const biSizeFontXxl = 36;
export const biSizeFontXxxl = 48;
export const biSizeSpaceXxs = 4;
export const biSizeSpaceXs = 8;
export const biSizeSpaceS = 12;
export const biSizeSpaceM = 16;
export const biSizeSpaceL = 24;
export const biSizeSpaceXl = 36;
export const biSizeSpaceXxl = 48;
export const biSizeLayoutXxs = 320;
export const biSizeLayoutXs = 480;
export const biSizeLayoutS = 600;
export const biSizeLayoutM = 768;
export const biSizeLayoutL = 960;
export const biSizeLayoutXl = 1280;
export const biSizeLayoutXxl = 1440;
export const biSizeBorderWeight = 1;
export const biSizeBorderRadiusS = 3;
export const biSizeBorderRadiusM = 6;
export const biSizeBorderRadiusPill = 9999;
export const biSizeControlBlockXxs = 21; // Only use this block size for small inline controls, like tooltip toggles.
export const biSizeControlBlockXs = 24;
export const biSizeControlBlockS = 36;
export const biSizeControlBlockM = 48;
export const biFontFamily = "'Montserrat', sans-serif";
export const biFontWeightRegular = 400;
export const biFontWeightMedium = 500;
export const biFontWeightSemibold = 600;
export const biFontWeightBold = 700;
export const biLineHeight = 1.5;
export const biTransitionDurationFast = "100ms";
export const biTransitionDurationNormal = "200ms";
export const biTransitionDurationSlow = "400ms";
export const biTransitionTimingDefault = "cubic-bezier(0.4, 0, 0.2, 1)";